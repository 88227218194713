import { AMaterialLessonBlockTemplate } from "../../models";

const images = ["/images/figma_image_rect.png"];

export const Images: AMaterialLessonBlockTemplate[] = [
  // Image 1:
  {
    name: "image 1",
    template: {
      name: "image 1",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "60%",
      },
    },
  },

  // Image 2:
  {
    name: "image 2",
    template: {
      name: "image 2",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "40%",
      },
    },
  },

  // Image 3:
  {
    name: "image 3",
    template: {
      name: "image 3",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "100%",
      },
    },
  },
  // Image 4:
  {
    name: "image 4",
    template: {
      name: "image 4",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "30%",
      },
    },
  },

  // Image 5:

  {
    name: "image 5",
    template: {
      name: "image 5",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "50%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "50%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "100%",
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },

  {
    name: "image 6",
    template: {
      name: "image 6",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "40%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "40%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "100%",
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },

  {
    name: "image 7",
    template: {
      name: "image 7",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "100%",
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },

  {
    name: "image 8",
    template: {
      name: "image 8",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "33%",
            height: "33%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "33%",
            height: "33%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "33%",
            height: "33%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "100%",
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },

  {
    name: "image 9",
    template: {
      name: "image 9",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "25%",
            height: "25%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "25%",
            height: "25%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "25%",
            height: "25%",
          },
        },
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "25%",
            height: "25%",
          },
        },
      ],
      style: {
        width: "70%",
        margin: "auto",
        height: "100%",
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
];
